import React from 'react';

const Description = () => {
  return (
    <div className="flex flex-col items-center justify-center p-4 sm:p-12">
      <div className="w-full sm:w-3/4 md:w-5/6 lg:w-5/6">
        <p className="text-4xl">
          Vihaan Shah is a 20 year old software dev in Jersey
          <span className="text-gray-400">
            {' '}
            — building the future of education at{' '}
            <a href="https://solis.eco" style={{ color: '#3A86FF' }}>
              solis.eco
            </a>
          </span>
        </p>
      </div>
      <div className="w-full sm:w-3/4 md:w-5/6 lg:w-5/6 mt-8 sm:mt-20 flex flex-col sm:flex-row sm:justify-between text-xl sm:text-2xl leading-8 sm:leading-10 text-gray-400">
        <div className="mb-8 sm:mb-0 sm:pr-8">
          <p>
            Vihaan, a visionary who's all about crafting solutions that make life better with an idea at a time. With 6
            years of coding wizardry (almost like harry potter) under his belt, he's a master of JavaScript, Python, and
            Java. Right now, he's cooking up a game-changing idea to revolutionize continuous learning with the power of
            Artifcial Intellegence. As he holds a strong vision where education is the root of a better life and hopes to
            change the world by providing a medium for growth.
          </p>
        </div>
        <div className="sm:pl-8">
          <p>
            When he's not being fully productive, Vihaan loves to jam out and create music, diving deep into genres like
            deep-house, afrobeats, reggae, and lo-fi making music under the name Oinky Beats(planning on changing that
            soon). Don't hesitate to drop a follow! He's also a sports fanatic, always down for a game of soccer or
            tennis. So if you're looking for a friendly match, hit him up (passing on a nice tennis match🎾 is hard to
            ever pass)!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Description;