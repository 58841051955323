import React from 'react';
import Solis from '../assets/projects/solis.png';
import Krishna from '../assets/projects/krishnagpt.png';
import NJSTEM from '../assets/projects/njstem.png';
import Quantel from '../assets/projects/quantel.png';
import Percy from '../assets/projects/percy.png';
import Stream from '../assets/projects/stream.png';
import Codebrute from '../assets/projects/codebrute.png';
import PMReady from '../assets/projects/pmready.png';
import Clock from '../assets/projects/clock.png';

const ProjectCard = ({ project, description, status, image, link }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'done':
        return 'text-green-500';
      case 'unfinished':
        return 'text-red-500';
      case 'in progress':
        return 'text-yellow-500';
      case 'contributor':
        return 'text-orange-300';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 p-6">
      <div className="overflow-hidden">
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src={image} alt={project} className="w-full h-64 object-cover rounded-lg transition duration-300 ease-in-out transform hover:scale-105" />
        </a>
        <div className="mt-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl text-gray-800">
              <a href={link} target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 transition duration-300">
                {project}
              </a>
            </h3>
            <span className={`text-sm font-medium ${getStatusColor()}`}>{status}</span>
          </div>
          <p className="text-gray-600 mt-2">{description}</p>
        </div>
      </div>
    </div>
   );
};

const Projects = () => {
  const projects = [
    {
      project: 'Solis',
      description: 'the future of education',
      status: 'in progress',
      image: Solis,
      link: 'https://solis.eco',
    },
    {
      project: 'KrishnaGPT',
      description: 'your spiritual guide',
      status: 'done',
      image: Krishna,
      link: 'https://krishnagpt.net',
    },
    {
      project: 'NJ STEM',
      description: 'hands on education for all',
      status: 'contributor',
      image: NJSTEM,
      link: 'https://njstem.net',
    },
    {
      project: 'Quantel AI',
      description: 'build the future of Fintech',
      status: 'contributor',
      image: Quantel,
      link: 'https://quantel-demo-site.netlify.app/',
    },
    {
      project: 'Percy',
      description: 'personal AI',
      status: 'done',
      image: Percy,
      link: 'https://percy.wowis.app/',
    },
    {
      project: 'Stream',
      description: 'dont pay for sport streaming',
      status: 'done',
      image: Stream,
      link: 'https://stream.wowis.app/',
    },
    {
      project: 'Code Brute',
      description: 'not good enough at coding?',
      status: 'done',
      image: Codebrute,
      link: 'https://cb.solis.eco/',
    },
    {
      project: 'PM Ready',
      description: 'learn project management',
      status: 'contributor',
      image: PMReady,
      link: 'https://www.pmready.online/',
    },    {
      project: 'Clock',
      description: 'trying to manage time',
      status: 'done',
      image: Clock,
      link: 'https://clock.wowis.app/',
      },
    // Add more projects as needed
  ];

  return (
    <div className="w-full sm:w-3/4 md:w-5/6 lg:w-3/4 mx-auto p-12">
      <div className="flex flex-wrap items-center justify-center">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            project={project.project}
            description={project.description}
            status={project.status}
            image={project.image}
            link={project.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
