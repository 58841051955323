import React from 'react';
import City from '../assets/nyc.jpg';

const Image = () => {
  return (
    <div className="flex justify-center items-center p-4">
      <img
        src={City}
        alt="New York City"
        className="rounded-3xl shadow-md border border-white w-full sm:w-3/4 md:w-5/6 lg:w-5/6"
      />
    </div>
  );
};

export default Image;
