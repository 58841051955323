import React from 'react';
import { Mail, Github, Twitter, FileText } from 'lucide-react';

const Navbar = () => {
  return (
    <nav className="bg-white p-12">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-black text-3xl flex items-center">
          Vihaan Shah
        </div>
        <div className="flex space-x-4">
          <a href="mailto:vihaan.shah2014@gmail.com" title="Email" className="text-gray-400 hover:text-gray-600">
            <Mail size={24} />
          </a>
          <a href="https://github.com/vihaanshah2014" title="GitHub" className="text-gray-400 hover:text-gray-600">
            <Github size={24} />
          </a>
          <a href="https://x.com/thepixelvoyage" title="Twitter" className="text-gray-400 hover:text-gray-600">
            <Twitter size={24} />
          </a>
          <a href="https://read.cv/vihaanshah" title="CV" className="text-gray-400 hover:text-gray-600">
            <FileText size={24} />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
