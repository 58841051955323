import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Image from './components/Image';
import Description from './components/Description';
import Projects from './components/Projects';

export default function App() {
  return (
    <div className="bg-[#ffffff] text-2xl">
      <Navbar />
      <Image />
      <Description />
      <Projects />
    </div>
  );
}
